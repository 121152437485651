<template>
  <ApiLoadingController class="map-wrapper" :fetch="fetch" :params="params">
    <template #default="{ data }">
      <MapController
        v-bind="data"
        :notes="bookmarks"
        :initial-state="initialState"
        :grid-positions="gridPositions"
        :grid-live="true"
        @update:initialState="updateInitialState"
      />
    </template>
  </ApiLoadingController>
</template>

<script>
import { mapGetters } from 'vuex'

import mapPage from '@/pages/mixins/mapPage'

import { getEmeaBroadcastMatch, getEmeaMatchBookmarks, setEmeaMatchBookmarks } from '../../api/grid.js'
import axios from '../../axios.js'
import ApiLoadingController from '../../components/controllers/ApiLoadingController.vue'
import MapController from '../../components/Map/MapController.vue'
import genRoundId from '../../components/Map/utils/genRoundId.js'
import abbrv from '../../utils/abbrv.js'
import { convertMinimapData } from '../../utils/convertMinimapData.js'
import prepareMinimapDetectedData from '../../utils/prepareMinimapDetectedData.js'
import processMapToolData from '../../utils/processMapToolData.js'
import rotate from '../../utils/rotateLocation.js'

const GRID_WIN_TYPE_OUTCOME = {
  bombExploded: 'Detonate',
  bombDefused: 'Defuse',
  opponentEliminated: 'Elimination',
  timeExpired: 'Time',
}

export default {
  name: 'GridLiveMapPage',
  mixins: [mapPage],
  components: { MapController, ApiLoadingController },
  props: {
    id: Number,
    mapName: String,
    pos: String,
  },
  data: () => ({
    bookmarks: [],
  }),
  computed: {
    ...mapGetters({
      abilitiesByGridName: 'static/abilitiesByGridName',
      agentsById: 'static/agentsById',
      agentsByModelName: 'static/agentsByModelName',
      agentsByName: 'static/agentsByName',
      agentsByNameLowercase: 'static/agentsByNameLowercase',
      agentsList: 'static/agentsList',
      gearsList: 'static/gearsList',
      mapsByAnything: 'static/mapsByAnything',
      mapsByNameLowecase: 'static/mapsByNameLowecase',
      mapsByUrl: 'static/mapsByUrl',
      weaponsList: 'static/weaponsList',
      weaponsByNameLowercase: 'static/weaponsByNameLowercase',
      gridPositions: 'auth/grid_positions',
    }),
    params() {
      return { id: this.id, mapName: this.mapName }
    },
  },
  watch: {
    params: {
      handler() {
        this.bookmarks = []
        this.loadBookmarks()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async loadBookmarks() {
      const allBookmarks = await Promise.all(
        [this.id].map(async id => {
          try {
            return await getEmeaMatchBookmarks({ seriesId: id, mapName: this.mapName })
          } catch (e) {
            console.warn(`Failed to load bookmarks for ${id} ${this.mapName}`, e)
            return []
          }
        })
      )
      this.bookmarks = Object.freeze(
        allBookmarks
          .flat()
          .filter(Boolean)
          .map(o => Object.freeze(o))
      )
    },
    async createBookmark(bookmark) {
      bookmark = Object.freeze(bookmark)
      this.bookmarks = Object.freeze([...this.bookmarks, bookmark])
      await setEmeaMatchBookmarks({ seriesId: this.id, mapName: this.mapName, bookmarks: this.bookmarks })
    },
    async fetch({ id, mapName }) {
      const matchInfo = await getEmeaBroadcastMatch({ id })
      console.log('matchInfo', matchInfo)

      const calcOutcome = round => {
        if (round.outcome == null) return null
        if (!GRID_WIN_TYPE_OUTCOME[round.outcome]) {
          throw new Error(`unknown win type ${round.outcome}`)
        } else {
          return GRID_WIN_TYPE_OUTCOME[round.outcome]
        }
      }

      const lastGameState = mapName
        ? matchInfo.find(
            g => g.map && this.mapsByAnything[g.map.toLowerCase()] === this.mapsByAnything[mapName.toLowerCase()]
          )
        : matchInfo.findLast(g => g.rounds.length > 11) || matchInfo[matchInfo.length - 1]
      console.log('lastGameState', lastGameState)

      const mapData = this.mapsByAnything[lastGameState.map.toLowerCase()]
      console.log('mapData', mapData)
      const map = mapData.id
      const rotateDeg = mapData.rotate_deg ? -mapData.rotate_deg : 0

      const firstRound = lastGameState.rounds.reduce((min, cur) => (min.num < cur.num ? min : cur))
      const matchTeams = await Promise.all(
        lastGameState.teams
          .map(gameTeam => ({
            gameTeam,
            firstRoundTeam: firstRound.teams.find(t => t.id === gameTeam.id),
          }))
          .map(async ({ gameTeam, firstRoundTeam, grid = firstRoundTeam.side === 'defender' ? 'Blue' : 'Red' }) => ({
            id: gameTeam.id,
            abbr: this.$store.getters['static/getTeamAbbrByName'](gameTeam.name) || abbrv(gameTeam.name),
            name: gameTeam.name,
            grid,
            team_side: grid,
            rounds_won: gameTeam.score,
            image: gameTeam.logo_url,
            players: gameTeam.players
              .filter(gamePlayer => gamePlayer.agent)
              .map(gamePlayer => ({
                id: gamePlayer.id,
                puuid: gamePlayer.id,
                name: gamePlayer.name,
                game_name: gamePlayer.name,
                agent: this.agentsByNameLowercase[gamePlayer.agent === 'UNKNOWN' ? 'gekko' : gamePlayer.agent].id,
                agent_id: this.agentsByNameLowercase[gamePlayer.agent === 'UNKNOWN' ? 'gekko' : gamePlayer.agent].id,
                team_side: grid,
              })),
          }))
      )
      console.log('matchTeams', matchTeams)

      const matchId = `${id}.${mapData.name}`

      const matchesData = [
        {
          id: matchId,
          created: new Date(firstRound.start).getTime(),
          teams: matchTeams,
          rounds: lastGameState.rounds
            .filter(r => r?.start && r?.end)
            .map(round => ({
              round_num: round.num - 1,
              round_length_millis: new Date(round.end).getTime() - new Date(round.start).getTime(),
              result_code: calcOutcome(round),
              teams: round.teams
                .map(roundTeam => ({ roundTeam, matchTeam: matchTeams.find(t => t.id === roundTeam.id) }))
                .map(({ roundTeam, matchTeam }) => ({
                  id: roundTeam.id,
                  grid: matchTeam.grid,
                  role: roundTeam.side === 'defender' ? 'def' : 'atk',
                  won: roundTeam.won,
                })),
              kills: round.killfeed_events.map(({ event, t }) => ({
                killer_puuid: event.actor.id,
                victim_puuid: event.target.id,
                revive: event.type === 'player-revived-player',
                round_time_millis: t * 1000,
                finishing_damage: this.weaponsByNameLowercase[
                  Object.keys(event.actor.stateDelta.round?.weaponKills || {}).pop()
                ]
                  ? {
                      damage_type: 'Weapon',
                      damage_item:
                        this.weaponsByNameLowercase[Object.keys(event.actor.stateDelta.round.weaponKills).pop()].id,
                    }
                  : {},
              })),
            })),
          //   // TODO: fill player stats
          //   // player_stats: extraData.finalData.roster
          //   //   .filter(player => player.wasAlive)
          //   //   .map(player => ({
          //   //     puuid: player.player_id,
          //   //     team_side:
          //   //       (extraData.finalData.rounds[1].team === 'attack' && player.teammate) ||
          //   //       (extraData.finalData.rounds[1].team !== 'attack' && !player.teammate)
          //   //         ? 'Red'
          //   //         : 'Blue',
          //   //     assists:
          //   //       extraData.finalData.rounds[roundNum + 1].phases.end.scoreboard_end.find(
          //   //         item => item.player_id === player.player_id
          //   //       )?.assists -
          //   //       (extraData.finalData.rounds[roundNum]?.phases.end.scoreboard_end.find(
          //   //         item => item.player_id === player.player_id
          //   //       )?.assists || 0),
          //   //     // TODO: compute this
          //   //     damages: [
          //   //       {
          //   //         bodyshots: 0,
          //   //         headshots: 0,
          //   //         legshots: 0,
          //   //         total: 0,
          //   //       },
          //   //     ],
          //   //     kills:
          //   //       extraData.finalData.rounds[roundNum + 1].phases.end.scoreboard_end.find(
          //   //         item => item.player_id === player.player_id
          //   //       ).kills -
          //   //       (extraData.finalData.rounds[roundNum]?.phases.end.scoreboard_end.find(
          //   //         item => item.player_id === player.player_id
          //   //       ).kills || 0),
          //   //     deaths:
          //   //       extraData.finalData.rounds[roundNum + 1].phases.end.scoreboard_end.find(
          //   //         item => item.player_id === player.player_id
          //   //       ).deaths -
          //   //       (extraData.finalData.rounds[roundNum]?.phases.end.scoreboard_end.find(
          //   //         item => item.player_id === player.player_id
          //   //       ).deaths || 0),
          //   //     loadout_value: 0,
          //   //     remaining: extraData.finalData.rounds[roundNum + 1].phases.shopping.scoreboard_end.find(
          //   //       item => item.player_id === player.player_id
          //   //     ).money,
          //   //     // TODO: get it from somewhere
          //   //     spent: 0,
          //   //     armor_id: null,
          //   //     weapon_id: null,
          //   //   })),
          // })),
          advancedData: await Promise.all(
            lastGameState.rounds
              .filter(r => r?.start && r?.end && (r?.clips?.minimap || r?.clips?.full))
              .map(async mlRound => ({
                round_num: mlRound.num - 1,
                ...(await (async () => {
                  try {
                    return {
                      minimap_video_url: (
                        await axios.get(
                          `https://jzxvxq32t24zqtnhp7hu2zx56m0dehqb.lambda-url.us-east-1.on.aws/series/${id}/clips/${mlRound.clips.minimap}/url`
                        )
                      ).data,
                    }
                  } catch (e) {
                    console.warn('Unable to resolve clip url', e)
                  }
                })()),
                ...(await (async () => {
                  try {
                    return {
                      replay_video_url: (
                        await axios.get(
                          `https://jzxvxq32t24zqtnhp7hu2zx56m0dehqb.lambda-url.us-east-1.on.aws/series/${id}/clips/${mlRound.clips.full}/url`
                        )
                      ).data,
                    }
                  } catch (e) {
                    console.warn('Unable to resolve clip url', e)
                  }
                })()),
                replay_video_start_time: 0,
                replay_video_end_time: new Date(mlRound.end).getTime() - new Date(mlRound.start).getTime(),
              }))
          ),
          replay: {
            video_id: `/test-ml/stream`,
            video_platform: 'augment',
            cropper_status: 'succeeded',
            // TODO: set correct values
            video_start_time: 0,
            video_end_time: 10000000,
          },
          vod_status: 'succeeded',
        },
      ]
      console.log('matchesData', matchesData)

      const roundAdvancedPositionsData = {}
      const roundSmokesData = {}
      const roundUtilitiesData = {}
      const roundWallsData = {}
      // const orbsData = {}
      const utilitiesUsage = {}

      lastGameState.rounds
        .filter(r => r?.start && r?.end)
        .forEach(roundData => {
          if (!roundData) return
          if (!roundData.inferences?.minimap) return
          const vodProcessedData = {}

          const roundId = genRoundId(matchId, roundData.num - 1)
          const teams = matchesData[0].rounds.find(round => round.round_num + 1 === roundData.num)?.teams
          if (!teams) return
          const minimapData = convertMinimapData(
            roundData.inferences.minimap,
            matchTeams.flatMap(team => team.players),
            teams,
            this.agentsList
          )
          vodProcessedData[roundId] = [
            ...(!this.gridPositions && this.pos !== 'grid' && minimapData?.player_locations?.length
              ? minimapData?.player_locations?.map(event => {
                  return { ...event, location: rotate(event.location, rotateDeg), type: 'agent' }
                }) || []
              : Object.entries(roundData.player_states || {})
                  .map(([puuid, states]) => [
                    matchTeams.flatMap(t => t.players).find(p => p.id === puuid).puuid,
                    states,
                  ])
                  .flatMap(([puuid, states]) =>
                    states?.positions
                      .filter(
                        (pos, idx, list) =>
                          idx === 0 ||
                          pos.t - list[idx - 1].t > 1.75 ||
                          pos.xy[0] !== list[idx - 1].xy[0] ||
                          pos.xy[1] !== list[idx - 1].xy[1]
                      )
                      .map(pos => ({
                        type: 'agent',
                        puuid,
                        location: { x: pos.xy[0], y: pos.xy[1] },
                        round_time_millis: pos.t * 1000,
                        track_id: puuid,
                      }))
                  )),
            ...(minimapData?.ability_locations?.map(event => {
              return { ...event, location: rotate(event.location, rotateDeg), type: 'ability' }
            }) || []),
            ...(minimapData?.smoke_locations?.map(event => {
              return { ...event, location: rotate(event.location, rotateDeg), type: 'smoke' }
            }) || []),
            ...(minimapData?.spike_locations?.map(event => {
              return { ...event, location: rotate(event.location, rotateDeg), type: 'spike' }
            }) || []),
            ...(minimapData?.wall_vectors?.map(event => {
              return {
                ...event,
                location: rotate(event.location, rotateDeg),
                vector: event.vector.map(l => rotate(l, rotateDeg)),
                type: 'wall',
              }
            }) || []),
          ]
          console.log(roundId, 'vodProcessedData', vodProcessedData)
          utilitiesUsage[roundId] = Object.entries(roundData.player_states.abilities || {}).flatMap(
            ([puuid, abilities]) =>
              Object.entries(abilities).flatMap(([abilityGridName, states]) => {
                const ability = this.abilitiesByGridName[abilityGridName]
                if (!ability) {
                  console.warn('unknown ability', abilityGridName, 'for', puuid)
                  return []
                }
                if (ability.slot === 'Ultimate') return []
                return states
                  .map((state, idx, arr) => {
                    const prevState = arr[idx - 1]
                    if (!prevState || prevState.ready === state.ready) return
                    return {
                      round_time_millis: state.t * 1000,
                      puuid,
                      utility: ability.slot.toLowerCase(),
                      count: state.ready ? 1 : 0,
                      oldCount: prevState.ready ? 1 : 0,
                      maxCount: 1,
                      oldMaXCount: 1,
                    }
                  })
                  .filter(Boolean)
              })
          )
          console.log('utilitiesUsage', utilitiesUsage)

          const filter = () => true // raw => raw.round_time_millis <= maxTime
          const processedData = prepareMinimapDetectedData(vodProcessedData[roundId], {
            filter,
            includeSmokes: true,
            includeUtilities: true,
            includeWalls: true,
            mapRotatedDeg: 0,
          })
          console.log(roundId, 'processedData', processedData)
          if (!processedData) return

          roundAdvancedPositionsData[roundId] = processedData.positions
          roundSmokesData[roundId] = processedData.smokes
          roundUtilitiesData[roundId] = processedData.utilities
          roundWallsData[roundId] = processedData.walls
        })

      const data = await processMapToolData({
        staticData: { agentsData: this.agentsList, weaponsData: this.weaponsList, gearsData: this.gearsList },
        matchesData,
        filterSuspiciousAdvancedPositions: false,
        roundAdvancedPositionsData,
        excludeSomeAbilities: false,
        roundUtilitiesUsageData: utilitiesUsage,
        roundSmokesData,
        roundUtilitiesData,
        roundWallsData,
        hasKillLocations: false,
        reportMissingLocations: false,
      })

      window.mapData = data

      return {
        canAccessPlayback: true,
        canAccessUtilities: true,
        hasApiKills: false,
        hasApiPositions: false,
        hasEconomy: false,
        hasOutcome: false,
        hasPlants: false,
        hasReports: false,
        hasWins: false,
        hasVod: false,
        isScrim: false,
        createBookmarkApi: this.createBookmark,
        data: {
          ...data,
          map: mapData,
          matchesData: Object.fromEntries(matchesData.map(match => [match.id, Object.freeze(match)])),
        },
        map,
        notes: [],
        noteTags: this.noteTags,
        team: matchTeams[0].id,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-wrapper,
.map-wrapper2 {
  position: relative;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  overflow: hidden;
}
</style>
